import Navbar from "./Navbar"
import { useSelector } from "react-redux";
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react';
import { absoluteUrl } from "../components/getAbsoluteUrl";
import { userType } from "../components/global";
import { parseCookies } from 'nookies'
import HeadContent from '../components/HeadContent';
import Moment from 'moment';
import Script from 'next/script';


export default function dashboard() {

	const [month, setMonth] = useState(0)
	const [teacher, setTeacher] = useState(0)
	const [student, setStudent] = useState(0)
	const [studentCourse, setStudentCourse] = useState([])
	const [courseCount, setCourseCount] = useState(0)
	const [piechart, setPiechart] = useState(false)
	const [piechartData, setPiechartData] = useState([])
	const [piechartData2, setPiechartData2] = useState(false)
	const [bargraph, setBargraph] = useState(false)
	const [bargraph2, setBargraph2] = useState(false)
	const [topCourse, setTopCourse] = useState(false)


	const router = useRouter()
	const logindata = useSelector((state) => state.user);
	var islogged = logindata.islogged    // logindata will consist true or false  
	if (!islogged) {
		router.replace("/");
		return null;
	}
	var token = logindata.token

	let userID, user_Type, instituteId = null, isStripeConnectVerified, isInstitute = null;
	if (islogged) {
		userID = logindata.userdata._id
		user_Type = logindata.userdata.userType
		instituteId = logindata.userdata?.institute?._id;
		isStripeConnectVerified = logindata.userdata.isStripeConnectVerified;
		isInstitute = logindata.userdata?.institute?._id ?? false;
	}

	useEffect(async (req) => {

		const { url } = absoluteUrl(req);
		var jsondata1 = [];

		const [usersRes] = await Promise.all([
			fetch(`/api/userapi2?userType=${user_Type}&userId=${userID}&instituteId=${instituteId}`, {
				headers: {
					'Authorization': token,
				},
			}),
		]);
		const [users] = await Promise.all([
			usersRes.json(),
		]);

		var totalStudent = users.addDate.reduce(function (acc, val) { return acc + val; }, 0);
		if (users.success) {
			setBargraph(true)
			setTeacher(users.teachers)
			setStudent(totalStudent)
			setStudentCourse(users.data)
			setTopCourse(true)
			setCourseCount(users.courseCount)
			if (totalStudent == 0) {
				setBargraph2(true)
			}
		}


		var currentMonth = new Date()
		var newMonth = Moment(currentMonth).format('M')
		setMonth(users.addDate[newMonth - 1])

		async function getJson(url) {
			let response = await fetch(url, {
				headers: {
					'Authorization': token,
				},
			});
			let data = await response.json()
			return data;
		}

		async function main() {
			jsondata1 = await getJson(`${url}/api/pieforDashboard?userType=${user_Type}&userId=${userID}&instituteId=${instituteId}`)
			if (jsondata1.success) {
				setPiechart(true)
				setPiechartData(jsondata1.data)

				if (jsondata1.data[0] == null) {

					setPiechartData2(true)
				}
			}
			var ctx = document.getElementById("widgetChart5");
			var ctx1 = document.getElementById("percent-chart2");

			if (ctx) {
				ctx.height = 200;
				var myChart = new Chart(ctx, {
					type: 'bar',
					data: {
						labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
						datasets: [
							{
								label: "Students",
								// data: [78, 81, 80, 64, 65, 80, 70, 75, 67, 85, 66, 68],
								data: users.addDate,
								// data: graph,
								borderColor: "transparent",
								borderWidth: "0",
								backgroundColor: "#ccc",
							}
						]
					},
					options: {
						maintainAspectRatio: true,
						legend: {
							display: false
						},
						scales: {
							xAxes: [{
								display: false,
								categoryPercentage: 1,
								barPercentage: 0.65
							}],
							yAxes: [{
								display: false
							}]
						}
					}
				});
			}

			if (ctx1) {
				ctx1.height = 209;
				var myChart = new Chart(ctx1, {
					type: 'doughnut',
					data: {
						datasets: [
							{
								label: "My First dataset",
								// data: [50, 50],
								data: jsondata1.data,
								backgroundColor: [
									'#00b5e9',
									'#fa4251'
								],
								hoverBackgroundColor: [
									'#00b5e9',
									'#fa4251'
								],
								borderWidth: [
									0, 0
								],
								hoverBorderColor: [
									'transparent',
									'transparent'
								]
							}
						],
						labels: [
							'Passed',
							'Failed'
						]
					},
					options: {
						maintainAspectRatio: false,
						responsive: true,
						cutoutPercentage: 87,
						animation: {
							animateScale: true,
							animateRotate: true
						},
						legend: {
							display: false,
							position: 'bottom',
							labels: {
								fontSize: 14,
								fontFamily: "Poppins,sans-serif"
							}

						},
						tooltips: {
							titleFontFamily: "Poppins",
							xPadding: 15,
							yPadding: 10,
							caretPadding: 0,
							bodyFontSize: 16,
						}
					}
				});
			}
		}
		main();
	}, [])


	var Month = new Date()
	var currentMonth = Moment(Month).format('M')

	let admin = user_Type == userType.admin ? true : false
	let instituteAdmin = user_Type == userType.instituteAdmin ? true : false
	let cardSize;
	if (admin || instituteAdmin) {
		cardSize = "col-md-6 col-lg-3"
	} else {
		cardSize = "col-md-6 col-lg-4"
	}

	let navigateUnVerifiedProfile = (user_Type == userType.instituteAdmin || (user_Type == userType.teacher && !isInstitute)) && !isStripeConnectVerified && !logindata.userdata.paypalUsername;

	const onClickAlert = async (e, path) => {
		e.preventDefault();
		if (navigateUnVerifiedProfile)
			if (confirm("You must need to verify your profile. Click OK to complete profile.") == true)
				await router.push(`/userProfile/${logindata?.userdata?._id}`);
			else
				e.preventDefault();
		else
			await router.push(path);
	}
	return (
		<>
			<HeadContent title={'Dashboard'} isBusyLoader={true} isMeta={true} />

			{islogged ?
				<div className="page-wrapper">
					<Navbar />
					{/* <!-- PAGE CONTENT--> */}
					<div className="page-content">
						{/* <!-- WELCOME--> */}
						<section className="welcome p-t-10">
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<h1 className="title-4">Dashboard</h1>
										<hr className="line-seprate" />
									</div>
								</div>
							</div>
						</section>
						{/* <!-- END WELCOME--> */}
						{/* <!-- STATISTIC--> */}
						<section className="statistic statistic2">
							<div className="container">
								<div className="row">
									<div className={cardSize}>
										{month != 0 ?
											<a href={'#'}>
												<div className="statistic__item statistic__item--green" onClick={(e) => onClickAlert(e, `/students?usertype=${user_Type}&userId=${userID}&instituteId=${instituteId}&currentMonth=${currentMonth}`)}>
													<h2 className="number">{month}</h2>
													<span className="desc">New Students</span>
													<div className="icon">
														<i className="fas fa-user"></i>
													</div>
												</div>
											</a>
											: <div className="statistic__item statistic__item--green">
												<h2 className="number">{month}</h2>
												<span className="desc">New Students</span>
												<div className="icon">
													<i className="fas fa-user"></i>
												</div>
											</div>}
									</div>
									<div className={cardSize}>
										{student != 0 ?
											<a href={'#'}>
												<div className="statistic__item statistic__item--primary" onClick={(e) => onClickAlert(e, `/students?usertype=${user_Type}&userId=${userID}&instituteId=${instituteId}`)}>
													<h2 className="number">{student}</h2>
													<span className="desc">Total Students</span>
													<div className="icon">
														<i className="fas fa-user"></i>
													</div>
												</div>
											</a>
											: <div className="statistic__item statistic__item--primary">
												<h2 className="number">{student}</h2>
												<span className="desc">Total Students</span>
												<div className="icon">
													<i className="fas fa-user"></i>
												</div>
											</div>}
									</div>
									{user_Type == userType.admin || user_Type == userType.instituteAdmin ?
										<div className="col-md-6 col-lg-3">
											<a href={'#'}>
												<div className="statistic__item statistic__item--orange" onClick={(e) => onClickAlert(e, `/teachers?usertype=${user_Type}&userId=${userID}&instituteId=${instituteId}`)}>
													<h2 className="number">{teacher}</h2>
													<span className="desc">Total Instructors</span>
													<div className="icon">
														<i className="fas fa-users"></i>
													</div>
												</div>
											</a>
										</div>
										: null}
									<div className={cardSize}>
										{courseCount != 0 ?
											<a href={'#'}>
												<div className="statistic__item statistic__item--blue" onClick={(e) => onClickAlert(e, `/courses?usertype=${user_Type}&userID=${userID}&instituteId=${instituteId}`)}>
													<h2 className="number">{courseCount}</h2>
													<span className="desc">Total courses</span>
													<div className="icon">
														<i className="fas fa-book"></i>
													</div>
												</div>
											</a>
											: <div className="statistic__item statistic__item--blue">
												<h2 className="number">{courseCount}</h2>
												<span className="desc">Total courses</span>
												<div className="icon">
													<i className="fas fa-book"></i>
												</div>
											</div>}
									</div>

								</div>
							</div>
						</section>
						{/* <!-- END STATISTIC--> */}

						{/* <!-- STATISTIC CHART--> */}
						<section className="statistic-chart">
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<h3 className="title-5 m-b-35">statistics</h3>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6 col-lg-4">
										{/* <!-- CHART--> */}
										<div className="statistic-chart-1">
											<h3 className="title-3 m-b-30">Monthly New Students</h3>
											<div className="chart-wrap" style={{ display: 'flex', alignItems: 'center' }}>
												{bargraph2 ?
													<div style={{ marginLeft: "3rem", marginBottom: '1.5rem', display: 'inline' }}>
														<h5>No data available!</h5>
													</div>
													: null
												}
												{!bargraph ? <div style={{ marginLeft: "4rem" }}>
													<h5>Loading....</h5>
												</div> :
													<canvas id="widgetChart5"></canvas>}
											</div>
											<div className="statistic-chart-1-note">
												<span className="big">{month}</span>
												<span>/ {student} User joined</span>
											</div>
										</div>
										{/* <!-- END CHART--> */}
									</div>
									<div className="col-md-6 col-lg-4">
										{/* <!-- TOP CAMPAIGN--> */}
										<div className="top-campaign">
											<h3 className="title-3 m-b-30">Top Courses</h3>
											<div className="table-responsive">
												<table className="table table-top-campaign">

													{admin ?
														<tbody>
															{
																studentCourse.map((x, index) => {
																	return (
																		<>
																			<tr key={index}>
																				<td>{x.courseName}</td>
																				<td>{x.courseObject.length}</td>
																			</tr>
																		</>
																	)
																})
															}
														</tbody>
														: <tbody>
															{
																studentCourse.map(function (value, idx) {
																	return (
																		<>
																			<tr key={idx}>
																				<td>{value[0]}</td>
																				<td>{value[1]}</td>
																			</tr>
																		</>)
																})
															}
														</tbody>
													}
												</table>
												{topCourse ? null : <div style={{ marginLeft: "4rem", marginTop: "4rem" }}>
													<h5>Loading....</h5>
												</div>}
												{topCourse && (Object.keys(studentCourse).length === 0 || studentCourse.length === 0) ? <div style={{ marginLeft: "4rem", marginTop: "5.5rem" }}>
													<h5>No data <br />available!</h5>
												</div> : null}
											</div>
										</div>
										{/* <!-- END TOP CAMPAIGN--> */}
									</div>
									<div className="col-md-6 col-lg-4">
										{/* <!-- CHART PERCENT--> */}
										<div className="chart-percent-2">
											<h3 className="title-3 m-b-30">Passing Ratio %</h3>
											<div className="chart-wrap" style={{ display: 'flex', alignItems: 'center' }}>
												{piechartData2 ?
													<div style={{ marginLeft: "4rem" }}>
														<h5>No data available!</h5>
													</div> : null}

												{piechart ? <canvas id="percent-chart2"></canvas> :
													<div style={{ marginLeft: "4rem" }}>
														<h5>Loading....</h5>
													</div>
												}


												<div id="chartjs-tooltip">
													<table></table>
												</div>
											</div>
											<div className="chart-info">
												<div className="chart-note">
													<span className="dot dot--blue"></span>
													<span>Pass</span>
												</div>
												<div className="chart-note">
													<span className="dot dot--red"></span>
													<span>Failed</span>
												</div>
											</div>
										</div>
										{/* <!-- END CHART PERCENT--> */}
									</div>
								</div>
							</div>
						</section>
						{/* <!-- END STATISTIC CHART--> */}

						{/* <!-- DATA TABLE--> */}
						<section className="p-t-20">
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<h3 className="title-5 m-b-35">Shortcuts</h3>
										{/* <a className="btn btn-success" >
											Student Result List
										</a> */}
										<a href={'#'}>
											<a style={{ marginLeft: 5 }} className="btn btn-primary" onClick={(e) => onClickAlert(e, `/courses?usertype=${user_Type}&userID=${userID}&instituteId=${instituteId}`)}>
												Courses List
											</a>
										</a>

										<a href={'#'}>
											<a style={{ marginLeft: 5 }} className="btn btn-primary" onClick={(e) => onClickAlert(e, `/lessons?usertype=${user_Type}&userID=${userID}&instituteId=${instituteId}`)}>
												Lessons List
											</a>
										</a>
										<a href={'#'}>
											<a style={{ marginLeft: 5 }} className="btn btn-warning" onClick={(e) => onClickAlert(e, `/exams?usertype=${user_Type}&userID=${userID}&instituteId=${instituteId}`)}>
												Assessment List
											</a>
										</a>
									</div>
								</div>
							</div>
						</section>
						{/* <!-- END DATA TABLE--> */}

						{/* <!-- COPYRIGHT--> */}
						<section className="p-t-60 p-b-20">
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										{/* <!--<div className="copyright">
                                <p>Ukoor 2018 Ukoor.  </p>
                            </div>--> */}
									</div>
								</div>
							</div>
						</section>
						{/* <!-- END COPYRIGHT--> */}
					</div>
				</div>
				: null}
			<Script src="/vendor/chartjs/Chart.bundle.min.js" />
		</>
	)
}