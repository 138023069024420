import Head from 'next/head';
import { appName } from './global';

const HeadContent = ({ title, isBusyLoader, isMeta, isDashboard, isAboutUs, stylePath, isTermCss }) => {
    return (
        <Head>
            <title>{title ? title : appName}</title>

            {stylePath ? <link href={stylePath} rel="stylesheet" /> : null}

            {title == 'Chat' ? <link href="/css/chat.css" rel="stylesheet" /> : null}

            {isTermCss ? <link href="/css/terms.css" rel="stylesheet" media="all" /> : null}

            <link rel="apple-touch-icon" sizes="180x180" href="/images/favicon/apple-touch-icon.png"></link>
            <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon/favicon-32x32.png"></link>
            <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon/favicon-16x16.png"></link>
            <link rel="icon" href="/images/favicon/favicon.ico" type="image/x-icon" />

            {/* {title == 'Course List' ? <link href="/vendor/font-awesome-4.7/css/font-awesome.min.css" rel="stylesheet" media="all" /> : null} */}

            {isBusyLoader ? <script src="https://cdn.jsdelivr.net/npm/pace-js@latest/pace.min.js"></script> : null}

            {isMeta ? <meta name="viewport" content="initial-scale=1.0, width=device-width" /> : null}

            {isAboutUs ? <style>{'body { background-color: #F7F7F7; }'}</style> : null}

        </Head>

    );
}
export default HeadContent;